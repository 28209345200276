
import { BrowserRouter as Router,useHistory } from 'react-router-dom';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge
} from 'mdbreact';
import {ContextPro} from '../Context/ContextVal'
// class TopNavigation extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       collapse: false
//     };
//     this.onClick = this.onClick.bind(this);
//     this.toggle = this.toggle.bind(this);
//     this.handleToggleClickA = this.handleToggleClickA.bind(this);
//   }

 
//   render() {
   
//     return (
     
//     );
//   }
// }

// export default TopNavigation;

import React,{useContext} from 'react'

const TopNavigation = (props) => {

const [num,setnum]=useContext(ContextPro)

  const navStyle = {
    paddingLeft: props.toggle ? '16px' : '240px',
    transition: 'padding-left .3s'
  };


  const history=useHistory()


 const handleToggleClickA=()=> {
    props.onSideNavToggleClick();
  }
const mylogout=()=>{
 window.localStorage.removeItem('login')
 setnum("")
 history.push('/')
}


  return (
    <div>
       <Router>
        <MDBNavbar
          className='flexible-MDBNavbar'
          light
          expand='md'
          scrolling
          fixed='top'
          style={{ zIndex: 3 }}
        >
          <div
            onClick={handleToggleClickA}
            key='sideNavToggleA'
            style={{
              lineHeight: '32px',
              marginleft: '1em',
              verticalAlign: 'middle',
              cursor: 'pointer'
            }}
          >
            <MDBIcon icon='bars' color='white' size='lg' />
          </div>

          <MDBNavbarBrand href='/' style={navStyle}>
            <strong>{props.routeName}</strong>
          </MDBNavbarBrand>
          <MDBNavbarNav expand='sm' right style={{ flexDirection: 'row' }}>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBBadge color='red' className='mr-2'>
                  3
                </MDBBadge>
                <MDBIcon icon='bell' />{' '}
                <span className='d-none d-md-inline'>Notifications</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right style={{ minWidth: '400px' }}>
                <MDBDropdownItem >
                  <MDBIcon icon='money-bill-alt' className='mr-2' />
                  New order received
                  <span className='float-right'>
                    <MDBIcon icon='clock' /> 13 min
                  </span>
                </MDBDropdownItem>
                <MDBDropdownItem >
                  <MDBIcon icon='money-bill-alt' className='mr-2' />
                  New order received
                  <span className='float-right'>
                    <MDBIcon icon='clock' /> 33 min
                  </span>
                </MDBDropdownItem>
                <MDBDropdownItem >
                  <MDBIcon icon='chart-line' className='mr-2' />
                  Your campaign is about to end
                  <span className='float-right'>
                    <MDBIcon icon='clock' /> 53 min
                  </span>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
            <MDBNavItem>
              <MDBNavLink to='#'>
                <MDBIcon icon='envelope' />
                <span className='d-none d-md-inline ml-1'>Contact</span>
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to='#'>
                <MDBIcon icon='comments' />
                <span className='d-none d-md-inline ml-1'>Support</span>
              </MDBNavLink>
            </MDBNavItem>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon='user' />{' '}
                <span className='d-none d-md-inline'>Profile</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu  style={{ minWidth: '200px'}}>
                <MDBDropdownItem  onClick={mylogout}>Log Out</MDBDropdownItem>
                <MDBDropdownItem >My Account</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarNav>
        </MDBNavbar>
      </Router>
    </div>
  )
}

export default TopNavigation
