import React, { Component } from 'react';
import '../App.css';
import SideNavigation from './SideNavigation';
import TopNavigation from './TopNavigation';
import Copyrights from './Footer';
import Routes from './Routes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: '',
      sideNavToggled: false,
      breakWidth: 1400
    };
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.assessLocation(this.props.location.pathname);
    }
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.assessLocation(this.props.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  toggleSideNav = () => {
    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled
      });
    }
  };

  assessLocation = location => {
    let locationString;
    switch (location) {
      // Dashboards:
      case '/dashboards/v1':
        locationString = 'داشبورد اول';
        break;
      case '/dashboards/v2':
        locationString = 'داشبورد دوم';
        break;
      case '/dashboards/v3':
        locationString = 'داشبورد سوم';
        break;
      case '/dashboards/v4':
        locationString = 'داشبورد چهارم';
        break;
      case '/dashboards/v5':
        locationString = 'داشبورد پنجم';
        break;
      case '/dashboards/v6':
        locationString = 'داشبورد ششم';
        break;

      // Pages
      case '/pages/customers':
        locationString = 'سفارشی';
        break;
      case '/pages/page-creator':
        locationString = 'Page creator';
        break;
      case '/pages/invoice':
        locationString = 'Invoice page';
        break;
      case '/pages/chat':
        locationString = 'چت';
        break;
      case '/pages/support':
        locationString = 'پشتیبانی';
        break;

      // Profiles
      case '/profile/v1':
        locationString = 'نمونه یک';
        break;
      case '/profile/v2':
        locationString = 'نمونه دو';
        break;
      case '/profile/extended':
        locationString = 'نمونه سوم';
        break;

      default:
    }
    this.setState({
      currentPage: locationString
    });
  };

  render() {
    const dynamicLeftPadding = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
    };

    return (
      <div className='app'>
        <div className='white-skin'>
          <SideNavigation
            breakWidth={this.state.breakWidth}
            style={{ transition: 'all .3s' }}
            triggerOpening={this.state.sideNavToggled}
            onLinkClick={() => this.toggleSideNav()}
          />
        </div>
        <div className='flexible-content white-skin'>
          <TopNavigation
            toggle={this.state.windowWidth < this.state.breakWidth}
            onSideNavToggleClick={this.toggleSideNav}
            routeName={this.state.currentPage}
            className='white-skin'
          />
          <main style={{ ...dynamicLeftPadding, margin: '8rem 6% 6rem' }}>
            <Routes onChange={() => this.assessLocation()} />
          </main>
          <Copyrights
            style={{ ...dynamicLeftPadding, position: 'fixed', width: '100%' }}
            className='d-none d-lg-block'
          />
        </div>
      </div>
    );
  }
}

export default App;
