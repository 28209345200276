import React,{useState,useEffect} from 'react'

export  const ContextPro=React.createContext()
export const ContextVal = ({children}) => {
    const[num,setnum]=useState()

useEffect(() => {
   setnum(window.localStorage.getItem('login'))
}, [])

    return (
        <div>
            <ContextPro.Provider value={[num,setnum]}>
            {children}
            </ContextPro.Provider>
        </div>
    )
}


