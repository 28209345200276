import React,{useContext} from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBIcon,
  MDBView,
  MDBBtn
} from 'mdbreact';
import Swal from 'sweetalert2'
import './Login.css';
import {ContextPro} from '../../Context/ContextVal'

export default function Login() {
  
  const [num,setnum]=useContext(ContextPro)
  const[logins,setlogins]=React.useState({
    email:'',
    password:''
  })

const mychange=(e)=>{

  const {name,value}=e.target

  setlogins({...logins,[name]:value})


}

const mysubmit=(e)=>{
e.preventDefault()

let emails=document.getElementById('email').value
let pass=document.getElementById('password').value

fetch('https://reqres.in/api/login',{
  method:'POST',
  headers:{
    'Accept':'application/json',
    'Content-Type':'application/json'
  },
  body:JSON.stringify(logins)

})
.then(res=>res.json())
.then(rsl=>{
  
  if(rsl.token==="" || rsl.error==="user not found" || emails==="" || pass===""){
   
    
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'error',
      title: 'ایمیل یا پسورد شما درست نمی باشد'
    })


  }else{

window.localStorage.setItem('login',rsl.token)
setnum(rsl.token)
  
  }
})

.finally(()=>{
  setlogins({
    email:'',
    password:''
  })
})



}

  return (
    <div className='classic-form-page' id='login'>
   

    <MDBView>
      <MDBMask
        className='d-flex justify-content-center align-items-center'
        overlay='stylish-strong'
      >
        <MDBContainer>
          <MDBRow>
            <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
              <MDBCard>
                <MDBCardBody>
                  <div className='form-header purple-gradient'>
                    <h6>email : eve.holt@reqres.in</h6> 
                     <h6>password : cityslicka</h6>
                   </div>
                
                  <MDBInput
                  name="email"
                    type='email'
                    label='Your email'
                    icon='envelope'
                    iconClass='white-text'
                    onChange={mychange}
                    value={logins.email}
                    id="email"
                  />
                  <MDBInput
                  name="password"
                    type='password'
                    label='Your password'
                    icon='lock'
                    iconClass='white-text'
                    onChange={mychange}
                    value={logins.password}
                    id="password"
                  />
                  <div className='text-center mt-3 black-text'>
                    <MDBBtn className='purple-gradient' size='lg' onClick={mysubmit}>
                      Sign in
                    </MDBBtn>
                    <hr />
                  </div>
                  <div className='inline-ul text-center d-flex justify-content-center'>
                    <a href='!#'>
                      <MDBIcon
                        fab
                        icon='twitter'
                        size='lg'
                        className='p-2 m-2  white-text'
                      />
                    </a>
                    <a href='!#'>
                      <MDBIcon
                        fab
                        icon='linkedin'
                        size='lg'
                        className='p-2 m-2 white-text'
                      />{' '}
                    </a>
                    <a href='!#'>
                      <MDBIcon
                        fab
                        icon='instagram'
                        size='lg'
                        className='p-2 m-2 white-text'
                      />
                    </a>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBMask>
    </MDBView>
   
  </div>
  )
}
