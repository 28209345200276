import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon
} from 'mdbreact';

class SideNavigation extends React.Component {
  // render MDBSideNav Link
  rSNL(to, text) {
    return (
      <MDBSideNavLink to={to} onClick={this.props.onLinkClick}>
        {text}
      </MDBSideNavLink>
    );
  }

  render() {
    const { onLinkClick } = this.props;
    return (
      <div className='white-skin'>
        <MDBSideNav
         
          mask='strong'
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          style={{ transition: 'padding-left .3s' }}
          
        >
          <form role='search' className='search-form'>
            <div className='form-group md-form mt-0 pt-1 ripple-parent'>
              <input
                type='text'
                placeholder='Search'
                className='form-control'
              />
            </div>
          </form>
          <MDBSideNavNav>
            <MDBSideNavCat
              name='صفحات اول'
              id='dashboard-cat'
              icon='tachometer-alt'
              
            >
              {this.rSNL('/dashboards/v1', 'صفحه اول')}
              {this.rSNL('/dashboards/v2', 'صفحه دوم')}
              {this.rSNL('/dashboards/v3', 'صفحه سوم')}
              {this.rSNL('/dashboards/v4', 'صفحه چهارم')}
              {this.rSNL('/dashboards/v5', 'صفحه پنجم')}
              {this.rSNL('/dashboards/v6', 'صفحه ششم')}
            </MDBSideNavCat>

            <MDBSideNavCat name='صفحات' id='pages-cat' icon='image'>
            
              {this.rSNL('/pages/register', 'ثبت نام')}
              {this.rSNL('/pages/pricing', 'قیمت ها')}
             {this.rSNL('/pages/about', 'درباره ما')}
             {this.rSNL('/pages/posts', 'لیست پست ها')}
              {this.rSNL('/pages/landing', 'صفحه تک')}
              {this.rSNL('/pages/customers', 'مشتری ها')}
             {this.rSNL('/pages/chat', 'چت')}
              {this.rSNL('/pages/support', 'پشتیبانی')}
             
            </MDBSideNavCat>

            <MDBSideNavCat name='پروفایل' id='profile-cat' icon='user'>
              {this.rSNL('/profile/v1', 'نمونه یک')}
              {this.rSNL('/profile/v2', 'نمونه دو')}
              {this.rSNL('/profile/extended', 'نمونه سوم')}
            </MDBSideNavCat>

    
       
          </MDBSideNavNav>
        </MDBSideNav>
      </div>
    );
  }
}

export default SideNavigation;
